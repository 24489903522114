<template>
  <v-card>
    <v-card-text>
      <v-tabs v-model="tab" right class="b-tab-horizontal">
        <v-tab href="#tab-1">Dados gerais</v-tab>
        <v-tab href="#tab-2">Dados do Produto</v-tab>

        <v-tabs-items v-model="tab" class="pt-6">
          <v-tab-item value="tab-1">
            <v-form
              ref="formTab1"
              lazy-validation
              v-model="validTab1"
              @submit.prevent="nextTab"
            >
              <v-row dense>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <BaseInsuranceCompany
                    id="insuranceCompanyId"
                    name="insuranceCompanyId"
                    label="Seguradora / Operadora"
                    required
                    :rules="[required]"
                    v-model="item.insuranceCompanyId"
                    :disabled="editMode"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <v-text-field
                    id="contractCode"
                    name="contractCode"
                    label="Código do contrato"
                    maxlength="15"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.contractCode"
                    :disabled="editMode"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <v-text-field
                    id="proposalCode"
                    name="proposalCode"
                    label="Código da proposta"
                    maxlength="15"
                    dense
                    outlined
                    required
                    :rules="[required]"
                    v-model="item.proposalCode"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseCustomerType
                    id="customerType"
                    name="customerType"
                    label="Tipo de cliente"
                    required
                    :rules="[required]"
                    v-model="item.customerType"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseDatePicker
                    id="effectiveDate"
                    name="effectiveDate"
                    label="Início de vigência"
                    required
                    :rules="[required]"
                    v-model="item.effectiveDate"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseDatePicker
                    id="expirationDate"
                    name="expirationDate"
                    label="Fim de vigência"
                    required
                    :rules="[required]"
                    v-model="item.expirationDate"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseBusinessType
                    id="businessType"
                    name="businessType"
                    label="Tipo de negócio"
                    required
                    :rules="[required]"
                    v-model="item.businessType"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseNumber
                    id="cutOffDay"
                    name="cutOffDay"
                    label="Dia de corte"
                    maxlength="2"
                    required
                    :rules="[required]"
                    v-model="item.cutOffDay"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseNumber
                    id="invoiceDay"
                    name="invoiceDay"
                    label="Dia de fatura"
                    maxlength="2"
                    required
                    :rules="[required]"
                    v-model="item.invoiceDay"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseNumber
                    id="expiryDay"
                    name="expiryDay"
                    label="Dia de vencimento"
                    maxlength="2"
                    required
                    :rules="[required]"
                    v-model="item.expiryDay"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                  <BaseFormReceipt
                    id="formReceipt"
                    name="formReceipt"
                    label="Forma de recebimento"
                    required
                    :rules="[required]"
                    v-model="item.formReceipt"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseMovimentType
                    id="movimentType"
                    name="movimentType"
                    label="Tipo de movimentação"
                    required
                    :rules="[required]"
                    v-model="item.movimentType"
                  />
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="6" v-if="editMode">
                  <BaseSituation
                    id="situation"
                    name="situation"
                    label="Status"
                    required
                    :rules="[required]"
                    :disabled="item.situation === 'Cancelado'"
                    v-model="item.situation"
                  />
                </v-col>
                <template v-if="productType !== 'Seguro de vida'">
                  <v-col cols="12" xl="4" lg="4" md="3" sm="6">
                    <v-checkbox
                      id="hasReissue"
                      name="hasReissue"
                      label="Possui remissão?"
                      color="primary"
                      class="ma-1"
                      v-model="item.hasReissue"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="6"
                    v-if="item.hasReissue"
                  >
                    <BaseReissuePeriod
                      id="reissuePeriod"
                      name="reissuePeriod"
                      label="Remissão"
                      required
                      :rules="[required]"
                      v-model="item.reissuePeriod"
                    />
                  </v-col>
                </template>
              </v-row>

              <v-divider class="my-5" />

              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <template v-if="editMode && item.situation !== 'Cancelado'">
                    <BaseButton
                      height="40"
                      outlined
                      color="error darken-1"
                      title="Cancelar contrato"
                      @click="cancel"
                      v-permission="{
                        roles: [
                          'A1',
                          'AC1',
                          'AC2',
                          'AC3',
                          'ACI1',
                          'ACI2',
                          'ACI3'
                        ],
                        operator: '==='
                      }"
                    />
                  </template>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="text-right">
                  <BaseButton
                    id="btn-back"
                    name="btn-back"
                    @click="back"
                    outlined
                    color="primary"
                    title="Voltar"
                  />
                  <template v-if="item.situation !== 'Cancelado'">
                    <BaseButton
                      id="btn-next"
                      name="btn-next"
                      type="submit"
                      color="primary"
                      title="Avançar"
                      :disabled="!validTab1"
                      v-permission="{
                        roles: [
                          'A1',
                          'AC1',
                          'AC2',
                          'AC3',
                          'ACI1',
                          'ACI2',
                          'ACI3'
                        ],
                        operator: '==='
                      }"
                    />
                  </template>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-form
              ref="formTab2"
              lazy-validation
              v-model="validTab2"
              @submit.prevent="save"
            >
              <v-row dense>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseNumber
                    id="breakEvenPercentage"
                    name="breakEvenPercentage"
                    label="Break even/limite técnico"
                    maxlength="3"
                    required
                    suffix="%"
                    :rules="[required]"
                    v-model="item.breakEvenPercentage"
                  />
                </v-col>

                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="productType !== 'Seguro de vida'"
                >
                  <BaseNumber
                    id="maximumAgeDependents"
                    name="maximumAgeDependents"
                    label="Idade máxima de dependentes"
                    maxlength="2"
                    required
                    :rules="[required]"
                    v-model="item.maximumAgeDependents"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="productType !== 'Seguro de vida'"
                >
                  <BaseNumber
                    id="maximumAgeUniversityDependents"
                    name="maximumAgeUniversityDependents"
                    label="Idade máxima de dependentes universitários"
                    maxlength="2"
                    required
                    :rules="[required]"
                    v-model="item.maximumAgeUniversityDependents"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseNumber
                    id="amountLives"
                    name="amountLives"
                    label="Quantidade de Vidas"
                    maxlength="6"
                    required
                    :rules="[required]"
                    v-model="item.amountLives"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseContractingType
                    id="contractingType"
                    name="contractingType"
                    label="Tipo de contratação"
                    required
                    :rules="[required]"
                    v-model="item.contractingType"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-3" />

              <v-row dense>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="productType !== 'Seguro de vida'"
                >
                  <v-radio-group
                    id="isContractGroupHolder"
                    name="isContractGroupHolder"
                    label="Grupo de Contrato"
                    class="ma-0"
                    v-model="item.isContractGroupHolder"
                  >
                    <v-radio label="Titular" color="primary" :value="true" />
                    <v-radio label="Todos" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <v-radio-group
                    id="isPrePaymentContractingMode"
                    name="isPrePaymentContractingMode"
                    label="Modalidade de Contratação"
                    class="ma-0"
                    v-model="item.isPrePaymentContractingMode"
                  >
                    <v-radio label="Pré" color="primary" :value="true" />
                    <v-radio label="Pós" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <v-radio-group
                    id="hasLeaveBeneficiaries"
                    name="hasLeaveBeneficiaries"
                    label="Possui beneficiários afastados?"
                    class="ma-0"
                    v-model="item.hasLeaveBeneficiaries"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="5"
                  lg="5"
                  md="5"
                  sm="6"
                  v-if="item.hasLeaveBeneficiaries"
                >
                  <BaseNumber
                    id="amountLeaveBeneficiaries"
                    name="amountLeaveBeneficiaries"
                    label="Quantidade de beneficiários"
                    maxlength="4"
                    required
                    :rules="[required]"
                    v-model="item.amountLeaveBeneficiaries"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-3" v-if="productType !== 'Seguro de vida'" />

              <v-row dense v-if="productType !== 'Seguro de vida'">
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <v-radio-group
                    id="hasCoparticipation"
                    name="hasCoparticipation"
                    label="Coparticipação"
                    class="ma-0"
                    v-model="item.hasCoparticipation"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="item.hasCoparticipation"
                >
                  <v-radio-group
                    id="hasAllPlans"
                    name="hasAllPlans"
                    label="Para todos os planos"
                    class="ma-0"
                    v-model="item.hasAllPlans"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="item.hasCoparticipation"
                >
                  <v-radio-group
                    id="hasCoparticipationPercentage"
                    name="hasCoparticipationPercentage"
                    label="Tipo de coparticipação"
                    class="ma-0"
                    v-model="item.hasCoparticipationPercentage"
                  >
                    <v-radio label="Percentual" color="primary" :value="true" />
                    <v-radio
                      label="Procedimento"
                      color="primary"
                      :value="false"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="
                  item.hasCoparticipation && item.hasCoparticipationPercentage
                "
              >
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseNumber
                    id="coparticipationPercentage"
                    name="coparticipationPercentage"
                    label="Percentual"
                    maxlength="3"
                    suffix="%"
                    required
                    :rules="[required]"
                    v-model="item.coparticipationPercentage"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="
                  item.hasCoparticipation && !item.hasCoparticipationPercentage
                "
              >
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseMoney
                    id="coparticipationProcedureMedicalCheck"
                    name="coparticipationProcedureMedicalCheck"
                    label="Consultas"
                    required
                    :rules="[required]"
                    v-model="item.coparticipationProcedureMedicalCheck"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseMoney
                    id="coparticipationProcedureEmergency"
                    name="coparticipationProcedureEmergency"
                    label="Pronto socorro"
                    required
                    :rules="[required]"
                    v-model="item.coparticipationProcedureEmergency"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseMoney
                    id="coparticipationProcedureMedicalExams"
                    name="coparticipationProcedureMedicalExams"
                    label="Exames"
                    required
                    :rules="[required]"
                    v-model="item.coparticipationProcedureMedicalExams"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-3" />

              <v-row dense>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <v-radio-group
                    id="isContributory"
                    name="isContributory"
                    label="Contributário"
                    class="ma-0"
                    v-model="item.isContributory"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="item.isContributory"
                >
                  <v-radio-group
                    id="isPercentageDiscountType"
                    name="isPercentageDiscountType"
                    label="Tipo de desconto"
                    class="ma-0"
                    v-model="item.isPercentageDiscountType"
                  >
                    <v-radio label="Percentual" color="primary" :value="true" />
                    <v-radio label="Valor" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="item.isContributory && item.isPercentageDiscountType"
              >
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseNumber
                    id="holderPercentage"
                    name="holderPercentage"
                    label="Titular"
                    maxlength="3"
                    suffix="%"
                    required
                    :rules="[required]"
                    v-model="item.holderPercentage"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseNumber
                    id="dependentPercentage"
                    name="dependentPercentage"
                    label="Dependente"
                    maxlength="3"
                    suffix="%"
                    required
                    :rules="[required]"
                    v-model="item.dependentPercentage"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                v-if="item.isContributory && !item.isPercentageDiscountType"
              >
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseMoney
                    id="holderValue"
                    name="holderValue"
                    label="Titular"
                    required
                    :rules="[required]"
                    v-model="item.holderValue"
                  />
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <BaseMoney
                    id="dependentValue"
                    name="dependentValue"
                    label="Dependente"
                    required
                    :rules="[required]"
                    v-model="item.dependentValue"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-3" />

              <v-row dense v-if="productType !== 'Assistência odontológica'">
                <v-col cols="12" xl="5" lg="5" md="5" sm="6">
                  <v-radio-group
                    id="dpsIsNecessary"
                    name="dpsIsNecessary"
                    label="É necessário preenchimento de DPS?"
                    class="ma-0"
                    v-model="item.dpsIsNecessary"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <template v-if="productType === 'Seguro de vida'">
                  <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                    <v-radio-group
                      id="salaryReadjustment"
                      name="salaryReadjustment"
                      label="Em reajuste salarial?"
                      class="ma-0"
                      v-model="item.salaryReadjustment"
                    >
                      <v-radio label="Sim" color="primary" :value="true" />
                      <v-radio label="Não" color="primary" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                    <v-radio-group
                      id="newAdmission"
                      name="newAdmission"
                      label="Em novas admissões?"
                      class="ma-0"
                      v-model="item.newAdmission"
                    >
                      <v-radio label="Sim" color="primary" :value="true" />
                      <v-radio label="Não" color="primary" :value="false" />
                    </v-radio-group>
                  </v-col>
                </template>
              </v-row>

              <v-row dense v-if="productType === 'Seguro de vida'">
                <v-col cols="12" xl="5" lg="5" md="5" sm="6">
                  <v-radio-group
                    id="dpsAgeLimit"
                    name="dpsAgeLimit"
                    label="DPS por limite de idade?"
                    class="ma-0"
                    v-model="item.dpsAgeLimit"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-if="item.dpsAgeLimit"
                >
                  <BaseNumber
                    id="ageLimit"
                    name="ageLimit"
                    label="Limite de idade"
                    maxlength="3"
                    v-model="item.ageLimit"
                    required
                    :rules="[required]"
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="productType === 'Seguro de vida'">
                <v-col cols="12" xl="5" lg="5" md="5" sm="6">
                  <v-radio-group
                    id="hasDpsMaximumCapitalLimit"
                    name="hasDpsMaximumCapitalLimit"
                    label="DPS por limite máximo de capital?"
                    class="ma-0"
                    v-model="item.hasDpsMaximumCapitalLimit"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  v-if="item.hasDpsMaximumCapitalLimit"
                >
                  <BaseMoney
                    id="maximumCapitalLimit"
                    name="maximumCapitalLimit"
                    label="Limite máximo de capital DPS"
                    required
                    :rules="[required]"
                    v-model="item.maximumCapitalLimit"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-3" v-if="productType === 'Seguro de vida'" />

              <v-row dense>
                <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                  <v-radio-group
                    id="acceptServiceProviders"
                    name="acceptServiceProviders"
                    label="Aceita prestadores de serviço?"
                    class="ma-0"
                    v-model="item.acceptServiceProviders"
                  >
                    <v-radio label="Sim" color="primary" :value="true" />
                    <v-radio label="Não" color="primary" :value="false" />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="5"
                  lg="5"
                  md="5"
                  sm="6"
                  v-if="item.acceptServiceProviders"
                >
                  <v-radio-group
                    id="isPercentageProviders"
                    name="isPercentageProviders"
                    label="Possui"
                    class="ma-0"
                    v-model="item.isPercentageProviders"
                  >
                    <v-radio
                      label="Quantidade de prestadores"
                      color="primary"
                      :value="false"
                    />
                    <v-radio
                      label="Percentual de prestadores"
                      color="primary"
                      :value="true"
                    />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  xl="3"
                  lg="3"
                  md="3"
                  sm="6"
                  v-if="
                    item.acceptServiceProviders && !item.isPercentageProviders
                  "
                >
                  <BaseNumber
                    id="amountProviders"
                    name="amountProviders"
                    label="Quantidade"
                    maxlength="4"
                    required
                    :rules="[required]"
                    v-model="item.amountProviders"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xl="3"
                  lg="3"
                  md="3"
                  sm="6"
                  v-if="
                    item.acceptServiceProviders && item.isPercentageProviders
                  "
                >
                  <BaseNumber
                    id="percentageProviders"
                    name="percentageProviders"
                    label="Percentual"
                    maxlength="3"
                    suffix="%"
                    required
                    :rules="[required]"
                    v-model="item.percentageProviders"
                  />
                </v-col>
              </v-row>

              <v-divider class="my-5" />

              <v-row>
                <v-col cols="12" class="text-right">
                  <BaseButton
                    id="btn-back-2"
                    name="btn-back-2"
                    outlined
                    color="primary"
                    title="Voltar"
                    @click="back"
                  />
                  <template v-if="item.situation !== 'Cancelado'">
                    <BaseButton
                      id="btn-save"
                      name="btn-save"
                      type="submit"
                      color="primary"
                      :title="editMode ? 'Atualizar' : 'Salvar'"
                      :disabled="!validTab2"
                      v-permission="{
                        roles: [
                          'A1',
                          'AC1',
                          'AC2',
                          'AC3',
                          'ACI1',
                          'ACI2',
                          'ACI3'
                        ],
                        operator: '==='
                      }"
                    />
                  </template>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <DialogCancelContract
        v-if="dialogCancel"
        :dialog="dialogCancel"
        :id="item.id"
        @close="closeCancelmodal"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    DialogCancelContract: () => import('./dialogs/dialog-cancel-contract')
  },

  props: {
    editMode: {
      type: Boolean
    },
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    tab: null,
    validTab1: true,
    validTab2: true,
    dialogCancel: false,
    situation: ['Ativo', 'Cancelado'],
    item: {
      id: null,
      insuranceCompanyId: null,
      contractCode: null,
      proposalCode: null,
      customerType: null,
      effectiveDate: null,
      expirationDate: null,
      businessType: null,
      cutOffDay: null,
      invoiceDay: null,
      expiryDay: null,
      formReceipt: null,
      movimentType: null,
      breakEvenPercentage: null,
      amountLives: null,
      contractingType: null,
      isPrePaymentContractingMode: true,
      hasLeaveBeneficiaries: false,
      amountLeaveBeneficiaries: null,
      maximumAgeDependents: null,
      maximumAgeUniversityDependents: null,
      hasReissue: false,
      reissuePeriod: null,
      isContractGroupHolder: true,
      hasCoparticipation: false,
      hasCoparticipationPercentage: false,
      coparticipationPercentage: null,
      coparticipationProcedureMedicalCheck: null,
      coparticipationProcedureEmergency: null,
      coparticipationProcedureMedicalExams: null,
      isContributory: false,
      isPercentageDiscountType: true,
      holderPercentage: null,
      dependentPercentage: null,
      holderValue: null,
      dependentValue: null,
      dpsIsNecessary: false,
      acceptServiceProviders: false,
      isPercentageProviders: true,
      percentageProviders: null,
      amountProviders: null,
      salaryReadjustment: false,
      newAdmission: false,
      dpsAgeLimit: false,
      ageLimit: null,
      hasDpsMaximumCapitalLimit: false,
      maximumCapitalLimit: null,
      hasAllPlans: true
    }
  }),

  mounted() {
    if (this.editMode) {
      this.search();
    }
  },

  methods: {
    async search() {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.getContractById(
          this.$route.params
        );

        if (status === 200) {
          this.item = data;
          this.$store.commit('contracts/contractById', data);
          this.$store.commit('contracts/createTabs', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    cancel() {
      this.dialogCancel = true;
    },

    closeCancelmodal(redirect) {
      this.dialogCancel = false;

      if (redirect) {
        this.back();
      }
    },

    save() {
      if (!this.$refs.formTab2.validate(true)) {
        return;
      }

      const params = {
        productType: this.productType,
        customerId: this.customerId,
        ...this.item
      };

      if (this.editMode) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.createContract(params);

        if (status === 200) {
          showMessage('success', 'Operação realizada com sucesso');

          this.$router.push({
            name: 'contratos-edit',
            params: { id: data.contractId }
          });

          this.search();
          this.$emit('updateInfo');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.updateContract(params);

        if (status === 200) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
          this.$emit('updateInfo');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    nextTab() {
      if (!this.$refs.formTab1.validate(true)) {
        return;
      } else {
        this.tab = 'tab-2';
      }
    }
  }
};
</script>
